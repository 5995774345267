import React from 'react';

function CategorySection({ title, items }) {
  return (
    <>
      <h2 id={title.toLowerCase()} className='text-2xl uppercase font-bold my-2 mx-1'>
        {title} {title === 'Carnes' ? <span className='text-xl'>*</span> : ''}
      </h2>
      {items.map(item => (
        <div key={item.id} className='flex bg-white justify-between shadow-md rounded-md mb-4 w-full'>
          <div className='flex flex-col justify-between p-2 py-2 lg:p-4'>
            <h2 className='text-base font-bold md:text-lg'>{item.title}</h2>
            <p className='text-sm md:text-base'>{item.description}</p>
            <h2 className='text-sm font-bold text-[#4d4d4d]'>R$ {item.price}</h2>
          </div>
          <img src={item.photo ? item.photo.url : '/images/Sem-Foto.png'} alt={item.title} className='w-[140px] rounded-tr-lg rounded-br-lg' />
        </div>
      ))}
    </>
  );
}

export default CategorySection;
