export function Aside() {
  return (
    <aside className="px-1 lg:sticky lg:top-[51px]">
      <div className="bg-white mt-2 flex flex-col w-full items-center text-center rounded-md shadow-md lg:w-[300px] lg:order-first lg:mr-4 lg:mt-3">
        <img
          src="/images/fachada.jpg"
          className="w-full rounded-t-md"
          alt="fachada"
        />

        <div className="flex flex-col items-center w-full text-center p-5 lg:min-w-[320px]">
          {/* <div className="py-2 w-[80%]">
                        <p className="font-bold text-green-600">Aberto</p>
                    </div>
                    <hr class="w-44 h-[1px] mx-auto my-1 bg-gray-100 border-0 rounded dark:bg-[#575c5c]" /> */}
          <div className="flex flex-col items-center px-4 py-2 w-full md:w-[400px] lg:w-full">
            <a
              href="https://api.whatsapp.com/send?1=pt_BR&phone=5562983432898"
              className="bg-[#06CF9C] text-white w-full px-8 m-2 h-12 font-semibold flex items-center justify-center gap-2 rounded-md hover:bg-[#008069] transition duration-200"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/whatsapp.png" alt="whatsapp" />
              Chama no Zap
            </a>
            <a
              href="tel:6232741039"
              className="bg-[#ff4d4d] text-white w-full px-8 m-2 h-12 font-semibold flex items-center justify-center gap-2 rounded-md hover:bg-[#ff1a1a] transition duration-200"
            >
              <img src="/images/phone-call.png" alt="telefone" />
              Ligar
            </a>
            <a
              href="https://www.google.com/maps/place/Carne+de+Sol+do+Edu/@-16.7002322,-49.3106263,17z/data=!4m5!3m4!1s0x935ef69141aa6f47:0x4abcdad4bf127b5e!8m2!3d-16.700285!4d-49.310606?hl=pt-BR"
              className="bg-[#ffd333] text-white w-full px-8 m-2 h-12 font-semibold flex items-center justify-center gap-2 rounded-md hover:bg-[#ffde66] transition duration-200"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/local.png" alt="localização" />
              Localização
            </a>
          </div>
          <hr class="w-44 h-[1px] mx-auto my-1 bg-gray-100 border-0 rounded dark:bg-[#b0b5b5]" />
          <div className="flex flex-col items-center w-[80%] pt-2">
            <p className="font-bold">Siga-nos nas redes sociais</p>
            <ul className="flex justify-around m-2 px-5">
              <li className="px-4 hover:opacity-80">
                <a
                  href="https://instagram.com/carnedesoldoedu"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/images/instagram.png"
                    alt="instagram carne de sol"
                  />
                </a>
              </li>
              <li className="px-4 hover:opacity-80">
                <a
                  href="https://www.facebook.com/carnedesoldoedu"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/images/facebook.png" alt="facebook carne de sol" />
                </a>
              </li>
              <li className="px-4 hover:opacity-80">
                <a
                  href="https://www.tiktok.com/@carnedesoldoedu"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/images/tiktok.png" alt="tiktok carne de sol" />
                </a>
              </li>
            </ul>
          </div>
          <hr class="w-44 h-[1px] mx-auto my-1 bg-gray-100 border-0 rounded dark:bg-[#b0b5b5]" />
          <a
            href="https://carnedesoldoedu.com.br"
            className="my-2 transition duration-200"
          >
            www.carnedesoldoedu.com.br
          </a>
        </div>
      </div>
    </aside>
  );
}
