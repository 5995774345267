import { Link } from 'react-scroll'

export function MenuTop() {
    return (
        <div id='top' className='flex flex-nowrap justify-start mt-[2px] pt-2 pb-1 w-full bg-[#F9F4F1] uppercase text-[#FAE374] overflow-auto sticky top-[52px] md:justify-between'>
            <Link to="carnes" spy={true} smooth={true} offset={-120} duration={500} className='text-base font-bold cursor-pointer m-1 p-2 bg-[#A72525] rounded-md hover:bg-[#CC3333]'>Carnes</Link>
            <Link to="entradas" spy={true} smooth={true} offset={-120} duration={500} className='text-base font-bold cursor-pointer m-1 p-2 bg-[#A72525] rounded-md hover:bg-[#CC3333]'>Entradas</Link>
            <Link to="porções" spy={true} smooth={true} offset={-120} duration={500} className='text-base font-bold cursor-pointer m-1 p-2 bg-[#A72525] rounded-md hover:bg-[#CC3333]'>Porções</Link>
            <Link to="acompanhamentos" spy={true} smooth={true} offset={-120} duration={500} className='text-base font-bold cursor-pointer m-1 p-2 bg-[#A72525] rounded-md hover:bg-[#CC3333]'>Acompanhamentos</Link>
            <Link to="bebidas" spy={true} smooth={true} offset={-120} duration={500} className='text-base font-bold cursor-pointer m-1 p-2 bg-[#A72525] rounded-md hover:bg-[#CC3333]'>Bebidas</Link>
            <Link to="drinks" spy={true} smooth={true} offset={-120} duration={500} className='text-base font-bold cursor-pointer m-1 p-2 bg-[#A72525] rounded-md hover:bg-[#CC3333]'>Drinks</Link>
            <Link to="sobremesas" spy={true} smooth={true} offset={-120} duration={500} className='text-base font-bold cursor-pointer m-1 p-2 bg-[#A72525] rounded-md hover:bg-[#CC3333]'>Sobremesas</Link>
        </div>
    )
}