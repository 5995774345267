import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { request } from 'graphql-request';
import { Link } from 'react-scroll';

import './App.css';

import { Header } from './components/Header';
import { Aside } from './components/Aside';
import { MenuTop } from './components/MenuTop';
import { Footer } from './components/Footer';
import { FooterInfos } from './components/FooterInfos';
import CategorySection from './components/CategorySection';

function App() {
  const [cardapios, setProducts] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      const { cardapios } = await request(
        'https://sa-east-1.cdn.hygraph.com/content/clz7ox2zs06hn07wdwpaykano/master',
        `
        {
          cardapios(orderBy: order_ASC, first: 100) {
            id
            title
            description
            price
            photo {
              url
            }
            categoria {
              title
              slug
            }
          }
        }
      `
      );

      setProducts(cardapios);
    };

    fetchProducts();
  }, []);

  if (!cardapios) {
    return (
      <div className="flex items-center">
        <img src="/images/loading.svg" alt="Loading" />
        <p className="text-xs md:text-base">Carregando...</p>
      </div>
    );
  }

  const categories = [
    'Carnes',
    'Entradas',
    'Porções',
    'Acompanhamentos',
    'Bebidas',
    'Drinks',
    'Sobremesas',
  ];

  return (
    <div id="home" className="flex min-h-screen flex-col items-center w-full">
      <Header />
      <main className="flex flex-col items-start justify-center px-2 py-2 mt-14 w-full lg:flex-row lg:w-[1120px] lg:py-6">
        <Aside />
        <div className="flex flex-col w-full">
          <MenuTop />
          <div className="px-1">
            <Router>
              {categories.map((category) => (
                <React.Fragment key={category}>
                  <CategorySection
                    title={category}
                    items={cardapios.filter(
                      (item) => item.categoria.title === category
                    )}
                  />
                  {category === 'Carnes' ? (
                    <p className="mb-5">
                      <strong>*</strong> Estes produtos contém alta concentração de sódio.
                    </p>
                  ) : null}
                  <hr className="h-[2px] mx-auto my-4 bg-slate-400/25 border-0 rounded md:my-8 dark:bg-slate-400/25" />
                </React.Fragment>
              ))}
              <p>Imagens meramente ilustrativas.</p>
            </Router>
          </div>
          <FooterInfos />
        </div>
      </main>

      <Link to="home" spy={true} smooth={true} offset={0} duration={500}>
        <button className="bg-[#CC3333] p-2 bottom-4 right-4 opacity-90 fixed border-none rounded-full hover:bg-[#A72525] transition-all">
          <img src="/images/up-arrow.png" alt="to top" />
        </button>
      </Link>

      <Footer />
    </div>
  );
}

export default App;
