export function FooterInfos() {
    return (
        <div className="w-full items-center p-4 mt-4 justify-center bg-white text-white shadow-md rounded-md">
            <div className="w-full">
                <p className="text-center font-bold text-lg">Aceitamos</p>
                <div className="flex justify-around items-center m-4 gap-4">
                    <div className="max-w-[20%]">
                        <img src="/images/mastercard.png" alt="Mastercard" />
                    </div>
                    <div className="max-w-[20%]">
                        <img src="/images/visa.png" alt="Visa" />
                    </div>
                    <div className="max-w-[20%]">
                        <img src="/images/elo.png" alt="Elo" />
                    </div>
                    <div className="max-w-[20%]">
                        <img src="/images/alelo-refeicao.png" alt="Alelo Refeicao" />
                    </div>
                </div>
                <p className="text-center font-bold">Não aceitamos PIX.</p>
                <p className="text-center font-bold">Não aceitamos cheques.</p>
            </div>
            <div className="w-full flex justify-center mt-4 mb-4">
                <img src="/images/proibido-fumar.jpg" className="w-[160px]" alt="Proibido Fumar" />
            </div>
            <div className="w-full">
                <p className="text-center font-bold">É proibida a venda de bebidas alcoólicas para menores de 18 anos.</p>
                <p className="text-center font-bold">Se beber não dirija!</p>
            </div>
            <hr class="w-44 h-[1px] mx-auto my-2 bg-gray-100 border-0 rounded md:my-4 dark:bg-[#b0b5b5]" />
            <div className="m-4">
                <h3 className="text-lg font-bold text-center">Horário de Atendimento</h3>
                <p className="text-center">
                    Terça à Sexta, 17:00h - 00:00h<br />
                    Sábado, 11:00h - 00:00h<br />
                    Domingo, 11:00h - 17:00h<br />
                    Feriados, 11:00h - 00:00h
                </p>
            </div>
            <hr class="w-44 h-[1px] mx-auto my-2 bg-gray-100 border-0 rounded md:my-4 dark:bg-[#b0b5b5]" />
            <div className="m-4">
                <h3 className="text-lg font-bold text-center">Endereço</h3>
                <p className="text-center">
                    Rua Ouro Preto, 413, Qd. 17, Lt. 2<br />
                    Praça Minas Gerais,<br />
                    Jardim Ana Lúcia<br />
                    74.315-220 - Goiânia-GO
                </p>
            </div>
            <hr class="w-44 h-[1px] mx-auto my-2 bg-gray-100 border-0 rounded md:my-4 dark:bg-[#b0b5b5]" />
            <div className='flex flex-col items-center m-4'>
                    <p className="font-bold">Siga-nos nas redes sociais</p>
                    <ul className='flex justify-around mt-4 px-5'>                        
                        <li className='px-4 hover:opacity-80'>
                            <a href="https://instagram.com/carnedesoldoedu" target="_blank" rel="noopener noreferrer">
                                <img src="/images/instagram.png" alt="instagram carne de sol" />
                            </a>
                        </li>
                        <li className='px-4 hover:opacity-80'>
                            <a href="https://www.facebook.com/carnedesoldoedu" target="_blank" rel="noopener noreferrer">
                                <img src="/images/facebook.png" alt="facebook carne de sol" />
                            </a>
                        </li>
                        <li className='px-4 hover:opacity-80'>
                            <a href="https://www.tiktok.com/@carnedesoldoedu" target="_blank" rel="noopener noreferrer">
                                <img src="/images/tiktok.png" alt="tiktok carne de sol" />
                            </a>
                        </li>                        
                    </ul>
                </div>
        </div>
    )
}

